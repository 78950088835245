import './App.css';
import { Button, ColorPicker, Flex, Form, Input } from 'antd';
import { useRef, useState } from 'react';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const BASE_PATH = "https://og-image-gen.alphaos.workers.dev"
function App() {
  const formRef = useRef({});
  const [url, setURL] = useState("")

  const onFinish = (values) => {
    formRef.current = values
    refresh();
  }

  const refresh = () => {
    let fullURL = new URL(BASE_PATH);
    for (const key in formRef.current) {
      if (formRef.current[key]) {
        fullURL.searchParams.set(key, formRef.current[key]);
      }
    }
    // Append time
    fullURL.searchParams.set('time', Date.now());
    setURL(fullURL.href);
  }

 
  return (
    <div className="App">
      <Flex 
      justify='space-between'
      style={{
        width: '100%',
      }}>
        <div style={{
          flex: 1
        }}>
          <Form 
           {...formItemLayout}
           onFinish={onFinish}
           initialValues={{
              title: 'Hello, World!',
              titleColor: '#1677ff',
              subTitle: 'This is a subtitle',
              subtitleColor: '#1677ff',
              backgroundColor: '#f0f0f0',
              frameDarkColor: '#000000',
              frameLightColor: '#ffffff',
              frameBackgroundImage: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png'
            
           }}
>
            <Form.Item label="Title" name="title">
              <Input placeholder="Title" />
            </Form.Item>
            <Form.Item label="Title Color" name="titleColor">
                <ColorPicker showText />
            </Form.Item>

            <Form.Item label="Subtitle" name="subTitle">
              <Input.TextArea placeholder="Subtitle" />
            </Form.Item>

            <Form.Item label="Subtitle Color" name="subtitleColor">

                <ColorPicker showText />
            </Form.Item>

            <Form.Item label="Background Color" name="backgroundColor">

                <ColorPicker showText />
            </Form.Item>


            <Form.Item label="Frame Background Color" name="frameDarkColor">

                <ColorPicker showText />
            </Form.Item>

            <Form.Item label="Frame Foreground Color" name="frameLightColor">

                <ColorPicker showText />
            </Form.Item>

            <Form.Item label="Frame Background Image" name="frameBackgroundImage">
              <Input placeholder="https://..." />
            </Form.Item>

            {/* Submit */}
            <Form.Item>
              <Button type="primary" htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </div>
        <Flex justify='center' style={{
          flex: 2
        }}>
          <div>
            <img 
              src={url} 
              alt="Generated OG" 
              style={{
                width: '100%',
                height: 'auto'
              }} 
            />
          </div>
          <Button onClick={() => {
            let a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.download = 'image.png';
            a.click();

          }}>
            Download
          </Button>
        </Flex>
      </Flex>
    </div>
  );
}

export default App;
